import axios from "axios";
import swal from "sweetalert";
import msalAuth from "./auth";
const instance = axios.create({
  baseURL: process.env.VUE_APP_END_POINT,
});
let timeout = null;
instance.interceptors.request.use(
  (config) => {
    let expiresTime = new Date(sessionStorage.getItem("expiresTime"));
    const accessTokenExpiration = expiresTime.getTime();
    let localTime = new Date();
    let timezoneOffset = localTime.getTimezoneOffset();
    let utcTime = new Date(localTime.getTime() - timezoneOffset);
    const browserTokenExpiration = utcTime.getTime();
    if (
      accessTokenExpiration < browserTokenExpiration &&
      sessionStorage.getItem("accessToken")
    ) {
      let account = JSON.parse(sessionStorage.getItem("account"));
      let request = {
        account: account,
        scopes: ["user.read"],
      };
      msalAuth.acquireTokenSilent(request).then((res) => {
        if (res) {
          config.headers.authorization = res.accessToken;
          sessionStorage.setItem("accessToken", res.accessToken);
          sessionStorage.setItem("expiresTime", res.expiresOn);
          sessionStorage.setItem("account", JSON.stringify(res.account));
        }
      });
    }
    
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (sessionStorage.getItem("accessToken")) {
        sessionStorage.setItem("sessionExpired", true);
        swal(
          "Your session is expired. Please login again !",
          "",
          "warning"
        ).then(() => {
          // localStorage.clear();
          sessionStorage.clear();
          msalAuth.logoutRedirect();
        });
      }
    }, 36000000);
    return response;
  },
  (error) => {
    if(error.code === "ERR_NETWORK"){
      swal("Low Internet connectivity", "", "warning").then(() => {
        window.location.reload();
      });
    }
    if ((error.response && error.response.data.status_code === 401) || !sessionStorage.getItem("accessToken")) {
      swal("User Authentication Failed!", "", "warning").then(() => {
        // localStorage.clear();
        sessionStorage.clear();
        msalAuth.logoutRedirect();
      });
    }
    if (error.response && error.response.data.status_code === 500) {
      swal(
        "Internal Server Error! Please, contact your Administrator.",
        "",
        "warning"
      ).then(() => {
        window.location.reload();
      });
    }
    if (
      (error.response && error.response.data.status_code === 400) ||
      (error.response && error.response.data.status_code === 404)
    ) {
      swal("Bad Request",error.response.data.error, "warning").then(() => { });
    }

    return Promise.reject(error);
  }
);
export default instance;
