<template>
  <v-dialog v-model="openDialog" width="40%">
    <v-card class="pa-6">
      <div
        class="display--flex justify-content-space-between align-items-center title-container mb-5 pb-5"
      >
        <span class="font--size-20 font--weight--bold">Add Row Details</span>
        <img
          class="cursor--pointer"
          src="../assets/cross-circle.svg"
          alt=""
          @click="closeDialog"
        />
      </div>
      <v-form ref="form">
        <v-row>
          <v-col lg="12" md="12" sm="12">
            <v-text-field
              label="Required Key"
              outlined
              v-model="form.target_var"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12" md="12" sm="12">
            <v-text-field
              label="Value"
              outlined
              v-model="form.value"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12" md="12" xl="12">
            <v-btn
              outlined
              tile 
              elevation="0"
              color="primary"
              class="btn--align"
              @click="closeDialog"
              >CANCEL</v-btn
            >
            <v-btn tile color="primary" class="btn--align" @click="saveData"
              >SAVE</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    openDialog: { type: Boolean, default: false },
    tableLength: { type: Number, default: 0 },
  },
  data() {
    return {
      form: {
        ID: null,
        target_var: "",
        value: "",
        confidence: 100,
        KeyConfidence: 100,
        validate: true,
      },
      requiredRules: [(v) => !!v || ``],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    saveData() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.form.ID = this.tableLength += 1;
        this.$emit("save-data", this.form);
        this.closeDialog();
      }
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss" scoped>
.mb-5 {
  margin-bottom: 20px !important;
}
.pb-5 {
  padding-bottom: 20px !important;
}
.title-container {
  border-bottom: 1px solid #ccc;
}
.pa-6 {
  padding: 24px !important;
}
</style>
