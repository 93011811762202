<template>
  <div>
    <div class="header">
      <div class="banner">
        <img class="header-logo" src="..//assets/logo.png" />
        <!-- <svg
          width="40"
          height="29"
          viewBox="0 0 52 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="logo"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.877808 1.25948C0.877808 2.19467 27.5783 40.7796 28.2121 40.7601C28.5575 40.7493 30.2833 38.2835 32.0484 35.2807L35.2567 29.8199L28.1641 19.4371C24.2631 13.7264 19.7548 7.18691 18.1448 4.90458L15.2171 0.755768H8.04694C4.10377 0.755768 0.877808 0.982244 0.877808 1.25948ZM40.7422 7.10101C42.7133 10.054 44.6118 12.4701 44.9611 12.4701C45.3103 12.4701 46.991 10.054 48.6962 7.10101L51.7956 1.73196H44.4774H37.1601L40.7422 7.10101Z"
            fill="white"
          />
        </svg> -->
        <div class="banner-name">vExtract</div>
        <div
          class="banner-name"
          style="
            margin-left: 1.2%;
            font-size: 20px;
            color: #ffffff;
          "
        >
          {{ pageName }}
        </div>
        <!-- <div class="banner-name" v-if="this.pageChildname !=='' " style="margin-left: 2%; font-size: 15px; color: #ffffff; margin-top: 0.5%;">{{pageChildName}}</div> -->
      </div>
      <div
        style="
          display: flex;
          width: 70%;
          align-items: center;
          justify-content: end;
          position: relative;
          gap: 1.875rem;
        "
      >
        <div class="icons-right">
          <v-tooltip bottom light>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="white"
                size="25"
                class="icon"
                style="margin-left: 0 !important"
                v-on="on"
                v-bind="attrs"
                @click="homePage"
                >mdi-home</v-icon
              ></template
            >
            <span>Home</span></v-tooltip
          >
          <!-- <v-icon color="white" size="20" class="icon" @click="$router.push('/form-templates')"
            > -->
          <v-tooltip bottom light>
            <template v-slot:activator="{ on, attrs }">
              <img
                src="../assets/Form-Template.png"
                class="icon icon-img"
                height="20"
                width="20"
                v-on="on"
                v-bind="attrs"
                v-if="roleType"
                style="margin-top:0.1%; margin-left: 0 !important"
                @click="$router.push('/form-templates')"
            /></template>
            <span>Form Templates</span></v-tooltip
          >
          <!-- </v-icon
          > -->
        </div>
        <div class="login">
          <v-avatar size="35" @click="showDropdown">
            <img
              v-if="profilePic"
              :src="'data:image/png;base64,' + profilePic"
              alt="User"
            />
            <v-icon v-else color="white" size="40">mdi-account-circle</v-icon>
          </v-avatar>
          <div class="profile-tile" v-if="showMenu">
            <v-icon
              color="black"
              size="20"
              class="icon"
              style="top: -10px; right: -140px"
              @click="closeProfile"
              >mdi-window-close</v-icon
            >
            <img
              v-if="profilePic"
              :src="'data:image/png;base64,' + profilePic"
              width="80"
              height="80"
              style="border-radius: 50%; border: 1px solid black"
              alt="User"
            />

            <v-icon
              v-else
              color="primary"
              size="100"
              style="margin-left: 23%; margin-bottom: 5%"
              >mdi-account-circle</v-icon
            >
            <span class="profile-tile-info">
              <div style="font-weight: 900">
                {{ this.accountName() }}
              </div>
              <div style="color: #6c6b6b">
                {{ this.accountUserName() }}
              </div>
            </span>
            <!-- <v-btn class="profile-tile-btn" color="primary" @click="signOut"
              >Sign-Out</v-btn
            > -->
            <v-row>
              <v-btn class="profile-tile-btn" color="primary" @click="signOut"
                >Sign-Out</v-btn
              >
              <v-icon
                v-if="roleType"
                color="black"
                size="25"
                class="profile-tile-btn"
                @click="$router.push({ name: 'settings', params: { id: '0' } })"
                >mdi-cog</v-icon
              ></v-row
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div>{{pageName}}</div> -->
  </div>
</template>
<script>
export default {
  data: () => ({
    showMenu: false,
  }),
  // props: ['pageName','pageChildName'],
  props: ["pageName"],
  computed: {
    profilePic() {
      return this.$store.state.photo;
    },
    roleType() {
      return sessionStorage.getItem("userRole") === "Admin" ? true : false;
    },
  },
  methods: {
    homePage() {
      this.$store.getters.queueType = "Unassigned";
      this.$router.push("/form-queue");
    },
    accountName() {
      return JSON.parse(sessionStorage.getItem("account")).name;
    },
    accountUserName() {
      return JSON.parse(sessionStorage.getItem("account")).username;
    },
    showDropdown() {
      this.showMenu = true;
    },
    closeProfile() {
      this.showMenu = false;
    },
    async signOut() {
      let params = {};
      params.email = JSON.parse(sessionStorage.getItem("account")).username;
      await this.axios.get(process.env.VUE_APP_END_POINT + "/logout", {
        params,
        headers: {
          accessToken: sessionStorage.accessToken,
        },
      });
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  width: 100%;
  height: auto;
  /* background-color: #1773bc; */
  background-color: #004A90;
  color: #ffffff;
  align-items: center;
  padding: 10px 30px;
}
.banner {
  width: 70%;
  display: flex;
  align-items: center;
}
.logo {
  margin-right: 1.2%;
}
.banner-name {
  border-left: solid white 1px;
  padding-left: 1.2%;
  height: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  align-items: center;
  display: flex;
}
.icons-right {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 95%;
  width: 85%;
  gap: 1.875rem;
}
.header-logo{
  height: 20px;
  padding-right: 1.2%;
}
/* img {
  border-radius: 50%;
} */
.icon {
  display: block;
  margin-left: 30px;
}
.icon-img {
  cursor: pointer;
}
.login {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 95%;
  /* width: 7%; */
}

.profile-tile {
  /* display: flex; */
  margin-left: 10%;
  top: 6vh;
  right: -2vh;
  position: absolute;
  background-color: rgb(245 245 245);
  padding: 2%;
  color: black;
  z-index: 8;
  /* justify-content: center; */
}
.profile-tile div {
  display: flex;
  line-height: 1;
  justify-content: center;
}

.profile-tile img {
  margin-left: 30%;
}
.profile-tile-btn {
  color: white;
  margin-left: 5%;
  margin-top: 10%;
}
</style>
