<template>
  <div>
    <loader v-if="loader"></loader>
    <div v-else>
      <v-data-table
        class="tagAlign"
        :headers="headers"
        :items="tableData"
        height="70vh"
        style="margin-bottom: 60px; border: #e0e0e0 solid 1px"
      >
        <!-- Table body -->
        <template slot="item" slot-scope="props">
          <tr
            v-bind:class="{
              unable: !props.item.enable,
            }"
          >
            <!-- first element in table -->
            <td>{{ props.index + 1 }}</td>

            <!-- second element in table -->
            <td>{{ props.item.field_name }}</td>

            <!-- third element in table -->
            <td>{{ props.item.rule_type }}</td>

            <!-- fourth element in table -->
            <td>
              <span
                v-if="props.item.rule_type == 'Lookup'"
                style="color: blue; cursor: pointer"
                @click="downloadFile(props.item.lookup_url)"
                title="Donwload File"
                >{{ props.item.rule }}</span
              >
              <span v-else>{{ props.item.rule }}</span>
            </td>

            <!-- fifth element in table -->
            <td>{{ props.item.description }}</td>

            <!-- sixth element in table -->
            <td>
              <div class="actionsMetadataTable">
                <div v-if="!props.item.edit">
                  <v-tooltip top color="#000000">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon @click="editForm(props.item)"
                          >mdi-square-edit-outline</v-icon
                        >
                      </span>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </div>

                <!-- Enable toggle -->
                <div>
                  <v-tooltip top color="#000000">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><v-switch v-model="props.item.enable" @click="onSwitch(props.item)"></v-switch
                      ></span>
                    </template>
                    <span>Enable/Disable</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="newMetadata" width="38vw" style="color: black">
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <span v-if="edit" class="font-Bold font--20"
                      >Edit Rule</span
                    >
                    <span v-else class="font-Bold font--20">Add Rule</span>
                  </v-col>
                </v-row>

                <v-row class="align--center">
                  <v-col cols="3">
                    <span class="font-Bold font--16">Rule Name</span>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      type="text"
                      v-model="newTableData.field_name"
                      outlined
                      class="font-Regular font--15"
                      :disabled="edit"
                      :rules="requiredRules"
                    >
                      <template #label>
                        Enter Rule Name
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="align--center">
                  <v-col cols="3">
                    <span class="font-Bold font--16">Rule Type</span>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      class="font-Regular font--15"
                      v-model="isRegex"
                      label="Regex"
                      :disabled="edit"
                      @change="handleCheckboxChange('a')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      class="font-Regular font--15"
                      v-model="isLookUp"
                      label="Lookup"
                      :disabled="edit"
                      :value="!isRegex"
                      @change="handleCheckboxChange('b')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="isRegex && !isLookUp" class="align--center">
                  <v-col cols="3">
                    <span class="font-Bold font--16">Regex</span>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      type="text"
                      v-model="newTableData.rule"
                      outlined
                      class="font-Regular font--15"
                      :rules="requiredRules"
                    >
                      <template #label>
                        Enter Regex
                        <span class="red--text"><strong>* </strong></span>
                      </template></v-text-field
                    >
                  </v-col>
                </v-row>
                <v-row v-if="!isRegex && isLookUp" class="align--center">
                  <v-col cols="3">
                    <span class="font-Bold font--16">File</span>
                  </v-col>
                  <v-col cols="9">
                    <v-file-input
                      class="font-Regular font--15"
                      label="Select File"
                      :rules="rules"
                      v-model="inputfile"
                      outlined
                    >
                      <template #label>
                        Select File
                        <span class="red--text"><strong>* </strong></span>
                      </template></v-file-input
                    >
                    <!-- <input
            ref="uploadFile"
            type="file"
          /> -->
                  </v-col>
                </v-row>
                <v-row class="align--center">
                  <v-col cols="3">
                    <span class="font-Bold font--16">Description</span>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      class="font-Regular font--15"
                      label="Enter Description"
                      type="text"
                      v-model="newTableData.description"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onCancel" class="mr-5">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              style="color: white;"
              tile
              @click="addNewMetadata"
              :loading="addbtnloader"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
export default {
  components: {
    Loader,
  },
  data: () => ({
    valid: false,
    requiredRules: [(v) => !!v || ""],
    ruleType: [],
    loader: false,
    newMetadata: false,
    edit: false,
    metadtaLength: 0,
    headers: [
      {
        text: "S.No",
        value: "s_no",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "7%",
      },
      {
        text: "Rule Names",
        value: "field_name",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "13%",
      },
      {
        text: "Rule Type",
        value: "rule_type",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "15%",
      },
      {
        text: "Rules",
        value: "rule",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "30%",
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "20%",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "15%",
      },
    ],
    isRegex: true,
    isLookUp: false,
    tableData: [],
    newTableData: {
      id: "",
      field_name: "",
      rule: "",
      description: "",
      rule_type: "",
      edit: false,
      enable: true,
    },
    inputfile: {},
    btnLoader: false,
    addbtnLoader: false,
    rules: [(v) => !!v.length || ""],
  }),
  props: {
    showAddMetaData: { type: Boolean, default: false },
  },
  computed: {
    isCheckedDependent() {
      return this.isRegex || this.isLookUp;
    },
  },
  watch: {
    showAddMetaData(val) {
      this.newMetadata = val;
    },
  },

  methods: {
    async onSwitch(item) {
      this.loader = true;
      let params = {};
      params.switchPage = "metadata";
      params.id = item._id.$oid;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/switch",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data.status_code == 200) this.loader = false;
    },
    downloadFile(url) {
      var link = document.createElement("a");
      link.href = url;
      link.click();
    },
    onCancel() {
      this.newMetadata = !this.newMetadata;
      this.newTableData.s_no = 0;
      this.newTableData.field_name = "";
      this.newTableData.rule = "";
      this.newTableData.description = "";
      // this.showAddMetaData = false;
      this.$emit("close", false);
      this.showAddMetaData = false;
      this.newMetadata = false;
      this.edit = false;
    },
    editForm(item) {
      this.newMetadata = true;
      this.showAddMetaData = true;
      this.edit = true;
      this.newTableData.field_name = item.field_name;
      this.newTableData.rule_type = item.rule_type;
      this.newTableData.id = item._id.$oid;
      if (this.newTableData.rule_type == "Regex") {
        this.isRegex = true;
        this.isLookUp = false;
        this.newTableData.rule = item.rule;
      } else {
        this.isRegex = false;
        this.isLookUp = true;
      }
      this.newTableData.description = item.description;
    },
    async addNewMetadata() {
      this.$refs.form.validate();
      if (this.newTableData.field_name) {
        this.addbtnLoader = true;
        let newData = {};
        const formData = new FormData();
        if (this.isRegex && !this.isLookUp) {
          newData = {
            field_name: this.newTableData.field_name,
            rule: this.newTableData.rule,
            rule_type: "Regex",
            enable: "True",
            description: this.newTableData.description,
          };
          formData.append("rule", newData.rule);
        } else {
          formData.append("file", this.inputfile);
          newData = {
            field_name: this.newTableData.field_name,
            rule_type: "Lookup",
            enable: "True",
            description: this.newTableData.description,
          };
        }
        formData.append("field_name", newData.field_name);
        formData.append("enable", newData.enable);
        formData.append("description", newData.description);
        formData.append("rule_type", newData.rule_type);
        let response = {};
        if (this.edit) {
          formData.append("id", this.newTableData.id);
          response = await this.axios.put(
            process.env.VUE_APP_END_POINT + "/metadata-list",
            formData,
            {
              headers: {
                accessToken: sessionStorage.accessToken,
              },
            }
          );
        } else {
          response = await this.axios.post(
            process.env.VUE_APP_END_POINT + "/metadata-list",
            formData,
            {
              headers: {
                accessToken: sessionStorage.accessToken,
              },
            }
          );
        }
        if (response.data.data == "Done" || response.data.data == "Updated") {
          this.addbtnLoader = false;
          this.newMetadata = !this.newMetadata;
          this.showAddMetaData = false;
          this.$emit("close", false);
          this.getData();
        }
        this.newTableData.s_no = 0;
        this.newTableData.field_name = "";
        this.newTableData.rule = "";
        this.newTableData.description = "";
        this.edit = false;
        this.newMetadata = false;
        this.showAddMetaData = false;
      }
      this.$refs.form.resetValidation();
    },

    // async editMetadataList(item) {
    //   this.btnLoader = true;
    //   let formatName = item.field_name;
    //   let format = item.rule;
    //   let description = item.description;
    //   let rule_type = item.rule_type;
    //   if (formatName.length != 0 && format.length != 0) {
    //     let result = {};
    //     result.id = item._id.$oid;
    //     result.field_name = formatName;
    //     result.description = description;
    //     result.rule = format;
    //     result.rule_type = rule_type;
    //     let response = await this.axios.put(
    //       process.env.VUE_APP_END_POINT + "/metadata-list",
    //       result,
    //       {
    //         headers: {
    //           accessToken: localStorage.accessToken,
    //         },
    //       }
    //     );
    //     if (response.data == "User Error") {
    //       alert("User Error! PLease login again");
    //       this.signOut();
    //     } else {
    //       if (response.data.data == "Updated") {
    //         this.btnLoader = false;
    //         this.showAddMetaData = false;
    //         this.$emit("close", false);
    //         this.getData();
    //       }
    //     }
    //   }
    // },
    async getData() {
      this.loader = true;
      this.tableData = [];
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/metadata-list",
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        let res = Object.values(response.data);
        let data = res[0];

        data = data.map((q) => ({
          ...q,
          
          edit: false,
        }));
        this.tableData = [...data];
        this.ruleType = response.data.rule_type;
        if (response.data.status_code == 200) this.loader = false;
      }
    },
    handleCheckboxChange(option) {
      if (option === "a") {
        this.isLookUp = false;
      } else if (option === "b") {
        this.isRegex = false;
      }
    },
    signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
    beforeUpdate() {
      this.newTableData.sNo = this.tableData.length + 1;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<!-- Global style -->
<style>
.tableHeadersMetadataList {
  font-family: "Gilroy-Extra Bold" !important;
  font-size: 16px;
  line-height: 19px;
  color: #2e2e30;
}
</style>

<!-- Local style -->
<style scoped>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
* {
  font-family: "Gilroy-Light";
}
.tagAlign {
  margin: auto 30px;
}
.belowNavbar {
  display: flex;
  justify-content: flex-end;
  height: 81.6px;
  align-items: center;
}
#addNewMetadataListButton {
  width: 100px;
  height: 39px;
}
.actionsMetadataTable {
  display: flex;
  justify-content: flex-start;
  padding-left: 0 !important;
  align-items: center;
}
.actionsMetadataTable div {
  width: 100%;
}
.addNewMetadataButton {
  width: 85px;
  height: 42px;
}
.evenRow {
  background: rgba(227, 229, 238, 0.2);
}
.extraButtons {
  cursor: pointer;
}
.unable {
  background-color: lightgray;
}
.v-data-table--fixed-height .v-data-table__wrapper {
  border: none;
}
.actionIcon:hover {
  /* color: #1773bc; */
  color: #004A90;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  overflow-wrap: anywhere;
}
</style>
