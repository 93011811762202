<template>
  <div :style="loading ? 'display:none;' : 'display:block;'">
    <span class="image-heading">Form Image</span>
    <img :src="urlImage" class="image" />
    <!-- <img src="../../form4.png" class="image" :style="{height: `${imageHeight}px`}"/> -->
    <div
      v-if="overlay"
      class="overlay"
      :style="{
        left: `${coordinates.left}px`,
        top: `${coordinates.top}px`,
        width: `${coordinates.width}px`,
        height: `${coordinates.height}px`,
      }"
    ></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    urlImage: "",
    urls: [],
  }),
  props: {
    coordinates: { type: Object, default: () => {} },
    overlay: { type: Boolean, default: false },
    imageHeight: { type: Number, default: 0 },
    tableData: { type: Array, default: () => [] },
    showImage: { type: Boolean, default: false },
    previewImage: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
  },
  methods: {
    async showPreview() {
      let url = process.env.VUE_APP_END_POINT + "/images";
      const params = {};
      params.id = this.$route.params.id;
      if (this.urls.length == 0) {
        let response = await this.axios.get(url, {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        });
        this.urls = response.data.data;
        this.urlImage = this.urls[0];
      }
    },
     signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
  },
  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.tableData.length) {
          this.showImage = true;
        }
      },
    },
    previewImage: {
      immediate: true,
      handler(data) {
        this.urlImage = this.urls[data];
      },
    },
  },
  mounted() {
    this.showPreview();
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
/* .outer-box {
  margin-left: 10px;
} */
.show {
  display: contents;
}

.hide {
  display: none;
}
.image {
  width: 600px;
  height: 606px;
  border-left: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-bottom: #e0e0e0 solid 1px;
  display: flex;
  position: relative;
}
.image-heading {
  display: flex;
  color: #fff;
  font-family: "Gilroy-Light";
  font-weight: 800;
  padding-top: 1%;
  padding-left: 4%;
  /* background-color: #1773bc; */
  background-color: #004A90;
  width: 600px;
  height: 30px;
}
.overlay {
  position: absolute;
  background-color: aqua;
  opacity: 30%;
}
</style>
