<template>
  <div>
    <loader v-if="loader"></loader>
    <div v-else>
      <v-row>
        <v-col
          class="pt-0"
          cols="showForm?8:12"
          style="margin-left: 30px; margin-right: 30px"
        >
          <v-data-table
            :headers="headers"
            fixed-header
            height="70vh"
            :items="itemsWithSno"
            :items-per-page="10"
            style="border: #e0e0e0 solid 1px"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.sno }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.role }}</td>
                <td>
                  <v-tooltip bottom light>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-6"
                        v-bind="attrs"
                        v-on="on"
                        @click="editForm(props.item)"
                        >mdi-pencil-outline</v-icon
                      >
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom light>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-6"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteUser(props.item)"
                        >mdi-trash-can-outline</v-icon
                      >
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col class="pt-0" v-if="showForm" cols="4">
          <v-card class="form-card">
            <v-card-title dense class="primary" style="color: white">
              <v-row>
                <v-col cols="11">
                  <h4 v-if="!editDetails">Add User Details</h4>
                  <h4 v-else>Edit User Details:</h4>
                </v-col>
                <v-col cols="1">
                  <v-icon size="20" color="white" @click="closeForm"
                    >mdi-window-close</v-icon
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <!-- <hr class="mb-5" color="grey" /> -->
              <v-card-text class="px-5 py-5">
                <!-- <v-container> -->
                <!-- <v-row class="mt-2 mb-1"><h3>Choose User:</h3></v-row> -->
                <v-row>
                  <v-col cols="6" class="px-6 py-6">
                    <v-select
                      v-model="selectedUser"
                      :items="users"
                      :rules="requiredRules"
                      :hide-details="auto"
                      :required="true"
                      @change="getUserDetails"
                      outlined
                      append-icon="mdi-magnify"
                    >
                      <template #label>
                        Choose User
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              v-model="searchTerm"
                              :rules="requiredRules"
                              :required="true"
                              placeholder="Search User Name"
                              @input="searchUsers"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="px-6 py-6">
                    <v-select
                      :items="roles"
                      :rules="requiredRules"
                      v-model="selectedRole"
                      outlined
                    >
                      <template #label>
                        User Role
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- <v-row class="mb-1"><h3>User Email Address:</h3></v-row> -->
                <v-row class="px-6 py-6">
                  <v-text-field
                    v-model="userEmail"
                    readonly
                    :rules="requiredRules"
                    outlined
                  >
                    <template #label>
                      User Email
                      <span class="red--text"><strong>* </strong></span>
                    </template></v-text-field
                  >
                </v-row>
                <!-- <v-row class="mb-1"><h3>User Role:</h3></v-row>              <v-row class="mb-13">                <v-select
                  :items="roles"
                  v-model="selectedRole"
                  filled
                ></v-select>              </v-row> -->
                <v-row
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 35vh;
                  "
                >
                  <v-btn
                    tile
                    elevation="0"
                    @click="onSubmitForm()"
                    color="primary"
                    class="submit-btn"
                    :loading="btnloader"
                    >Submit</v-btn
                  ></v-row
                >
              </v-card-text>
            </v-form>
            <!-- </v-container> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template><script>
import Loader from "./Loader.vue";
export default {
  components: { Loader },
  data: () => ({
    valid: false,
    requiredRules: [(v) => !!v || ""],
    headers: [
      {
        text: "S. No.",
        value: "sno",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        align: "start",
      },
      {
        text: "Email Address",
        value: "email",
        align: "start",
        sortable: false,
      },
      {
        text: "Role",
        value: "role",
        align: "start",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        align: "start",
        sortable: false,
      },
    ],
    tableData: [],
    users: [],
    userData: [],
    roles: [],
    // showForm: false,
    selectedUser: "",
    selectedRole: "",
    userEmail: "",
    selectedAccountID: "",
    editDetails: false,
    loader: false,
    btnloader: false,
    searchTerm: "",
    usersCopy: [],
  }),
  computed: {
    itemsWithSno() {
      return this.tableData.map((d, index) => ({ ...d, sno: index + 1 }));
    },
  },
  props: {
    showForm: { type: Boolean, default: false },
    required: { type: Boolean, required: false },
  },
  methods: {
    async getData() {
      this.loader = true;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/get-users",
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        if (response.data.status_code == 200) this.loader = false;
        this.tableData = response.data.data;
        this.roles = response.data.user_roles;
      }
    },
    async getUsers() {
      this.editDetails = false;
      this.selectedUser = "";
      this.userEmail = "";
      this.selectedRole = "";
      this.selectedAccountID = "";
      this.users = [];
      this.showForm = true;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/manage-users",
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        this.userData = response.data.data;
        this.userData.forEach((e) => {
          this.users.push(e.name);
        });
        this.usersCopy = [...this.users];
      }
    },
    getUserDetails() {
      this.userData.forEach((e) => {
        if (e.name == this.selectedUser) {
          this.userEmail = e.email;
          this.selectedAccountID = e.account_identifier;
        }
      });
    },
    editForm(item) {
      this.editDetails = true;
      this.showForm = true;
      this.users = item.name;
      this.selectedUser = item.name;
      this.userEmail = item.email;
      this.selectedRole = item.role;
      this.selectedAccountID = item.account_identifier;
    },
    async onSubmitForm() {
      this.$refs.form.validate();
      if (this.selectedUser) {
        this.btnloader = true;
        let sendUser = {};
        sendUser.name = this.selectedUser;
        sendUser.email = this.userEmail;
        sendUser.role = this.selectedRole;
        sendUser.account_identifier = this.selectedAccountID;
        let response = await this.axios.post(
          process.env.VUE_APP_END_POINT + "/manage-users",
          sendUser,
          {
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          }
        );
        if (response.data.data == "Done" || response.data.data == "Updated") {
          this.btnloader = false;
          this.showForm = false;
          this.selectedUser = "";
          this.userEmail = "";
          this.selectedRole = "";
          this.selectedAccountID = "";
          this.users = [];
          this.getData();
          this.$emit("close", false);
        }
        this.editDetails = false;
      }
      this.$refs.form.resetValidation();
    },
    closeForm() {
      this.showForm = false;
      this.editDetails = false;
      this.$emit("close", false);
    },
    async deleteUser(item) {
      let response = {};
      await swal({
        title: "Are you sure you want to delete this record?",
        text: "If you delete it, it will be gone forever.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((result) => {
        if (result) {
          this.loader = true;
          let params = {};
          params.id = item.account_identifier;
          response = this.axios.delete(
            process.env.VUE_APP_END_POINT + "/manage-users",
            {
              params,
              headers: {
                accessToken: sessionStorage.accessToken,
              },
            }
          );
        }
      });
      this.loader = false;
      this.getData();
    },
    searchUsers(e) {
      if (!this.searchTerm) {
        this.users = this.usersCopy;
      }
      this.users = this.usersCopy.filter((user) => {
        return user.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    },
    signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    showForm(val) {
      if (val && this.editDetails == false) this.getUsers();
    },
  },
};
</script><style>
.form-card {
  width: 100%;
  height: 100%;
}
.submit-btn {
  width: 40%;
  height: 70%;
  font-size: 1.5em;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px !important;
}
.tagAlign {
  margin: auto 30px;
}
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
.v-data-table.v-data-table--fixed-header.v-data-table--has-bottom.v-data-table.v-data-table--fixed-header
  thead
  th {
  background-color: #f4f5f8 !important;
  font-family: "Gilroy-Extra Bold" !important;
  font-weight: bolder;
}
</style>