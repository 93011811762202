<template>
  <div>
    <div>
      <head-section pageName="Form Templates"></head-section>
    </div>
    <loader v-if="loading" />
    <div v-else class="mt-5">
      <v-data-table @update:page="changeInPage" @update:items-per-page="changeInItems" :page.sync="pageNo"
        :server-items-length="totalCount" :items-per-page="itemsOnPage" class="tagAlign" :headers="headers" height="80vh"
        :items="tableData" style="margin-top: 20px; border: #e0e0e0 solid 1px">
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.index + 1 }}</td>

            <td>
              <!-- <div style="color: #1773bc">{{ props.item.formName }}</div> -->
              <div style="color: #004A90">{{ props.item.formName }}</div>
            </td>
            <td>
              <div style="color: #6e6f74">{{ props.item.datetime }}</div>
            </td>
            <td>
              <div style="color: #6e6f74">{{ props.item.count }}</div>
            </td>
            <td>
              <div class="actionsFormTemplates">
                <div v-if="!props.item.vmapped_dataset.length">
                  <v-tooltip top color="#000000">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"><v-icon v-bind:class="{ actionIcon: props.item.enable }" v-on="props.item.enable
                          ? {
                            click: () =>
                              mapFunction(props.item.vform_draft_id.$oid),
                          }
                          : {}
                        ">mdi-link-variant</v-icon>
                      </span>
                    </template>
                    <span>Map</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip top color="#000000">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"><v-icon @click="editTemplate(props.item)">mdi-pencil-outline</v-icon>
                      </span>
                    </template>
                    <span>Edit Mapping</span>
                  </v-tooltip>
                </div>
                <div>
                  <v-tooltip top color="#000000">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"><v-switch v-model="props.item.enable"
                          @click="onSwitch(props.item)"></v-switch></span>
                    </template>
                    <span>Enable/Disable</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import HeadSection from "../components/HeadSection.vue";
import Loader from "../components/Loader.vue";
import moment from "moment";
export default {
  components: {
    HeadSection,
    Loader,
  },
  data: () => ({
    headers: [
      {
        text: "S.No",
        value: "sNo",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "10%",
      },
      {
        text: "Form Templates",
        value: "formName",
        sortable: true,
        class: "tableHeadersMetadataList",
        width: "20%",
      },
      {
        text: "Date/Time",
        value: "datetime",
        sortable: true,
        class: "tableHeadersMetadataList",
        width: "30%",
      },
      {
        text: "Page Count",
        value: "count",
        sortable: true,
        class: "tableHeadersMetadataList",
        width: "20%",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "tableHeadersMetadataList",
        width: "20%",
      },
    ],
    tableData: [],
    loading: false,
    itemsOnPage: 10,
    pageNo: 1,
    totalPage: 0,
    totalCount: 0,
  }),

  methods: {
    async onSwitch(item) {
      this.loading = true;
      let params = {};
      params.switchPage = "templates";
      params.id = item._id.$oid;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/switch",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data.status_code == 200) this.loading = false;
    },
    getDateTime() {
      this.tableData.forEach((e) => {
        e.datetime = moment(e.datetime.$date).format("DD-MMM-YYYY, hh:mm A");
      });
    },
    mapFunction(id) {
      this.loading = true;
      this.$store.getters.form_id = id;
      this.$router.push({
        name: "datasetMapping",
        params: { id: id },
      });
    },
    editTemplate(item) {
      let tempid = item._id.$oid;
      let id = item.vform_draft_id.$oid;
      const route_params = `id=${id}&tid=${tempid}`;
      this.$router.push({
        name: "datasetMapping",
        params: { id: id },
        query: { tid: tempid },
      });
    },
    async changeInItems(event) {
      if (event != -1) this.itemsOnPage = event;
      else this.itemsOnPage = this.totalCount;
      await this.getData();
    },
    async changeInPage(event) {
      if (event) {
        this.pageNo = event;
      }
      await this.getData();
    },
    async getData() {
      this.loading = true;
      let params = {};
      params.route = "template";
      params.limit = this.itemsOnPage;
      params.offset = this.pageNo - 1 < 0 ? 0 : this.pageNo - 1;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/form-templates",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        if (response.data.status_code == 200) this.loading = false;
        let res = Object.values(response.data.data);
        let data = res;
        // data = data.map((q) => ({
        //   ...q,

        //   enable: true,
        // }));
        this.totalCount = response.data.total_count;
        this.totalPage = Math.ceil(this.totalCount / this.itemsOnPage);
        this.tableData = [...data];
        this.getDateTime();
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.v-data-table-header {
  background: rgba(227, 229, 238, 0.4);
}
</style>

<style scoped>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}

* {
  font-family: "Gilroy-Light";
}

.tagAlign {
  margin: 0 30px;
}

.addFormButton {
  width: 113px;
  height: 42px;
}

.v-data-table--fixed-height .v-data-table__wrapper {
  border: none;
}

.evenRow {
  background: rgba(227, 229, 238, 0.2);
}

.unable {
  background-color: lightgray;
}

.actionsFormTemplates {
  display: flex;
  justify-content: flex-start;
  margin-left: -25px;
  align-items: center;
}

.actionsFormTemplates div {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.actionIcon:hover {
  /* color: #1773bc; */
  color: #004A90;
}
</style>
