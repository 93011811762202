import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import FormDiscovery from "../views/FormDiscovery";
import DatasetMapping from "../views/DatasetMapping";
import FormTemplates from "../views/FormTemplates";
import FormQueue from "../views/FormQueue";
import Settings from "../views/Settings";
import CreateDatasetTemplate from "../views/CreateDatasetTemplate";
import ExtractionPage from "../views/ExtractionPage";
import store from "../store/index.js";

Vue.use(VueRouter);
function guardMyroute(to, from, next) {
  if (
    sessionStorage.getItem("accessToken") &&
    sessionStorage.getItem("account")
  ){
    // const currRoute = from.path.split("/");
    // let id = currRoute[1];
    // currRoute = currRoute[0];
    // if(currRoute = "dataset-mapping"){

    // }
    next();
  } else {
    swal("User Authentication Failed!", "", "warning").then(() => {
      // localStorage.clear();
      sessionStorage.clear();
      next("/");
    });
  }
}
function is_logged_in(to, from, next) {
  if (sessionStorage.getItem("accessToken")) {
    next("/form-queue");
  } else {
    next();
  }
}
const routes = [
  {
    path: "/",
    component: Login,
    beforeEnter: is_logged_in,
  },
  {
    name: "formDiscovery",
    path: "/form-discovery/:id",
    component: FormDiscovery,
    beforeEnter: guardMyroute,
  },
  {
    name: "datasetMapping",
    path: "/dataset-mapping/:id",
    component: DatasetMapping,
    beforeEnter: guardMyroute,
  },
  {
    name: "datasetMapping",
    path: "/dataset-mapping/:id/:tid",
    component: DatasetMapping,
    beforeEnter: guardMyroute,
  },
  {
    path: "/form-queue",
    component: FormQueue,
    beforeEnter: guardMyroute,
  },
  {
    path: "/form-templates",
    component: FormTemplates,
    beforeEnter: guardMyroute,
  },
  {
    name: 'settings',
    path: "/settings/:id",
    component: Settings,
    beforeEnter: guardMyroute,
  },
  {
    name: "createDatasetTemplate",
    path: "/create-dataset-template",
    component: CreateDatasetTemplate,
    beforeEnter: guardMyroute,
  },
  {
    name: "createDatasetTemplate",
    path: "/create-dataset-template/:id",
    component: CreateDatasetTemplate,
    beforeEnter: guardMyroute,
  },
  {
    name: "extractionPage",
    path: "/extracation-page/:id",
    component: ExtractionPage,
    beforeEnter: guardMyroute,
  },
];

const router = new VueRouter({
  store,
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // debugger
  if (store.state.isFormDirty) {
    swal({
      title: "Discard Changes",
      text: "You have some unsaved changes. Do you want to discard changes?",
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Your changes have been reverted", {
          icon: "success",
        });
        next();
        store.commit("cancel");
        store.state.isFormDirty = false;
      } else {
        next(false);
      }
    });
  } else if (!store.state.isFormDirty && from.params.id && !store.state.submit) {
    store.commit("cancel");
    next();
  } else {
    next();
  }
});

export default router;
