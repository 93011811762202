import { render, staticRenderFns } from "./ExtractionPage.vue?vue&type=template&id=4bf5c9d4&scoped=true&"
import script from "./ExtractionPage.vue?vue&type=script&lang=js&"
export * from "./ExtractionPage.vue?vue&type=script&lang=js&"
import style0 from "./ExtractionPage.vue?vue&type=style&index=0&id=4bf5c9d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf5c9d4",
  null
  
)

export default component.exports