import { render, staticRenderFns } from "./FormDiscovery.vue?vue&type=template&id=714c9693&scoped=true&"
import script from "./FormDiscovery.vue?vue&type=script&lang=js&"
export * from "./FormDiscovery.vue?vue&type=script&lang=js&"
import style0 from "./FormDiscovery.vue?vue&type=style&index=0&id=714c9693&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "714c9693",
  null
  
)

export default component.exports