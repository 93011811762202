<template>
  <div class="loader-css">
    <v-progress-circular indeterminate color="primary" :size="50">
      <v-overlay></v-overlay>
    </v-progress-circular>
  </div>
</template><script>
export default {};
</script><style scoped>
.v-progress-circular {
  margin: 1rem;
}
.loader-css {
  /* position: absolute;
  top: 45%;
  left: 50%; */
  margin-left: 48vw; 
  margin-top: 32vh;
  /* display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; */
}
</style>