<template>
  <div>
    <loader v-if="loader"></loader>
    <div class="tagAlign" v-else>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-data-table
            :headers="headers"
            fixed-header
            outlined
            height="70vh"
            style="border: #e0e0e0 solid 1px"
            :items="itemsWithSno"
            :items-per-page="10"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.sno }}</td>
                <td>{{ props.item.dataset_name }}</td>
                <td>{{ props.item.datetime }}</td>
                <td>
                  <v-row class="align-center">
                    <v-col cols="2">
                      <v-tooltip bottom light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            medium
                            v-bind="attrs"
                            v-on="on"
                            @click="editDataset(props.item)"
                            >mdi-square-edit-outline</v-icon
                          >
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3">
                      <v-tooltip bottom light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-switch
                            v-bind="attrs"
                            color="primary"
                            v-model="props.item.enable"
                            @click="onSwitch(props.item)"
                            v-on="on"
                          ></v-switch>
                        </template>
                        <span>Enable</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import moment from "moment";
export default {
  components: {
    Loader,
  },
  data: () => ({
    headers: [
      {
        text: "S. No.",
        value: "sno",
        class: "tableHeadersMetadataList",
        width: "10%",
        sortable: false,
      },
      {
        text: "Dataset Names",
        value: "dataset_name",
        align: "start",
        class: "tableHeadersMetadataList",
        width: "30%",
        sortable: false,
      },
      {
        text: "Date",
        value: "date",
        align: "start",
        class: "tableHeadersMetadataList",
        width: "30%",
        sortable: false,
      },
      {
        text: "Actions",
        class: "tableHeadersMetadataList",
        width: "30%",
        sortable: false,
      },
    ],
    tableData: [],
    loader: false,
  }),
  computed: {
    itemsWithSno() {
      return this.tableData.map((d, index) => ({ ...d, sno: index + 1 }));
    },
  },
  methods: {
    async onSwitch(item) {
      this.loader = true;
      let params = {};
      params.switchPage = "dataset";
      params.id = item._id.$oid;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/switch",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data.status_code == 200) this.loader = false;
    },
    async getData() {
      this.loader = true;
      
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/manage-dataset",
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        if (response.data.status_code == 200) this.loader = false;
        this.tableData = response.data.data;
        this.getDateTime();
      }
    },
    getDateTime() {
      this.tableData.forEach((e) => {
        e.datetime = moment(e.datetime.$date).format("DD-MMM-YYYY, hh:mm A");
      });
    },
    editDataset(item) {
      let id = item._id.$oid;
      this.$router.push({ name: "createDatasetTemplate", params: { id: id } });
    },
     signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
@font-face {
  font-family: "Gilroy-Extra Bold";
  src: local("Gilroy-Extra Bold"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../assets/fonts/Radomir Tinkov - Gilroy-Light.otf") format("opentype");
}
.tagAlign {
  margin: auto 30px;
}

.v-data-table.v-data-table--fixed-header.v-data-table--has-bottom.v-data-table.v-data-table--fixed-header
  thead
  th {
  background-color: #f4f5f8 !important;
  font-family: "Gilroy-Extra Bold" !important;
  font-weight: bolder;
}
</style>
