import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from "./services/axios-helper";
import VueAxios from "vue-axios";
import "./assets/styles/css/style.css";
import store from "./store/index";
import Vuex from "vuex";
import auth from "./services/auth";

Vue.use(VueAxios, axios, Vuex);
Vue.prototype.$msal = auth;

Vue.config.productionTip = false
Vue.config.silent = true
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')