var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('head-section',{attrs:{"pageName":"Continue Extraction"}}),(_vm.loading)?_c('loader'):_vm._e(),(!_vm.loading)?_c('v-row',{staticStyle:{"margin-top":"0.5%"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('form-preview',{staticStyle:{"margin-left":"30px"},attrs:{"coordinates":_vm.coordinates,"overlay":"overlay","showImage":_vm.showImage,"tableData":_vm.tableData,"previewImage":_vm.previewImage,"loading":_vm.loading}})],1),_c('v-col',{attrs:{"cols":"7"}},[(!_vm.loading)?_c('div',{staticClass:"outer-box"},[_c('div',{staticClass:"table-heading"},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"0.8%"},attrs:{"cols":"10"}},[_c('div',[_vm._v("Form Identifiers")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"tile":"","elevation":"0"},on:{"click":function($event){return _vm.addRow()}}},[_vm._v("Add Row")])],1)],1)],1),_c('v-data-table',{staticStyle:{"border-left":"#e0e0e0 solid 1px","border-right":"#e0e0e0 solid 1px","border-bottom":"#e0e0e0 solid 1px"},attrs:{"headers":_vm.headers,"fixed-header":"","height":"527","items":_vm.itemsWithSno,"items-per-page":10},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{on:{"mouseover":function($event){return _vm.getBoundingBox(props.item)},"mouseleave":function($event){_vm.overlay = false}}},[_c('td',[_vm._v(_vm._s(props.item.sno))]),_c('td',[_vm._v(_vm._s(props.item.target_var)+" "),(props.item.required)?_c('span',{staticClass:"red--text"},[_vm._v("*")]):_vm._e()]),_c('td',{staticClass:"table-input-container",on:{"dblclick":function($event){$event.stopPropagation();return _vm.saveEditData(props.item, 'edit')}}},[_c('div',{staticStyle:{"position":"relative"}},[(
                      props.item.value == 'SELECTED' ||
                      props.item.value == 'NOT SELECTED'
                    )?_c('input',{class:props.item.confidence >= 85
                        ? 'dark--green'
                        : props.item.confidence >= 70 && props.item.confidence < 85
                        ? 'orange--color'
                        : props.item.confidence < 70
                        ? 'red--color'
                        : '',staticStyle:{"border":"1px solid grey","border-style":"inset","padding":"5px"},attrs:{"type":"checkbox"},domProps:{"checked":props.item.value == 'SELECTED' ? true : false},on:{"input":function($event){return _vm.onInput($event, props.item.ID, 'checkbox')},"change":function($event){return _vm.checkEnabled(props.item.ID)}}}):(props.item.enableField)?_c('input',{staticStyle:{"border":"1px solid grey","border-style":"inset","padding":"5px"},attrs:{"type":"text"},domProps:{"value":props.item.value},on:{"input":function($event){return _vm.onInput($event, props.item.ID, 'input')}}}):(props.item.suggestions.length != 0)?_c('div',[_vm._l((props.item.value),function(p,index){return _c('p',{key:index,staticClass:"m--0",class:props.item.confidence >= 85 &&
                        props.item.suggestions[index]?.length == 0
                          ? 'dark--green'
                          : props.item.confidence >= 70 &&
                            props.item.confidence < 85 &&
                            props.item.suggestions[index]?.length == 0
                          ? 'orange--color'
                          : props.item.confidence < 70 && 
                            props.item.suggestions[index]?.length > 0
                          ? 'red--color'
                          : 'red--color',staticStyle:{"display":"inline-block","position":"relative"},style:(props.item.value.length - 1 != index
                          ? 'margin-right:10px !important;'
                          : 'margin-right:0px'),on:{"click":function($event){return _vm.openSuggestion(
                          props.item.suggestions[index],
                          index,
                          p
                        )}}},[_c('span',{class:props.item.suggestions[index]?.length > 0
                            ? 'incorrect-word-color'
                            : 'normal-word-color',staticStyle:{"cursor":"pointer"},attrs:{"id":'incorrectWord' + index}},[_vm._v(" "+_vm._s(p))])])}),(_vm.wordClick && _vm.suggestions.length)?_c('div',{staticClass:"elevation-4",staticStyle:{"top":"25px","background-color":"white","z-index":"2","position":"absolute","padding":"3%","border-radius":"5%"},style:(_vm.suggestionIndex > 0 ? 'left: 65px' : 'left:0px;')},[_c('ul',{staticStyle:{"list-style-type":"none","padding-right":"40px","padding-left":"12px"}},[_vm._l((_vm.suggestions),function(item,ind){return _c('li',{key:ind,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.replaceWord(item, _vm.suggestionIndex)}}},[_vm._v(" "+_vm._s(item)),(item == _vm.incorrectWord)?_c('v-icon',{attrs:{"size":"14","title":"This is extracted text (Worng Medical Term)"}},[_vm._v("mdi-exclamation")]):_vm._e()],1)}),_c('li',[_c('v-icon',{staticClass:"mr-5",attrs:{"size":"15"},on:{"click":function($event){_vm.wordClick = false}}},[_vm._v("mdi-cancel")]),_c('v-icon',{attrs:{"size":"15"},on:{"click":function($event){return _vm.addword(props.item)}}},[_vm._v("mdi-tray-plus")])],1)],2)]):_vm._e()],2):_c('p',{staticClass:"m--0",class:props.item.confidence >= 85
                        ? 'dark--green'
                        : props.item.confidence >=70 && props.item.confidence < 85
                        ? 'orange--color'
                        : props.item.confidence < 70
                        ? 'red--color'
                        : ''},[_vm._v(" "+_vm._s(props.item.value)+" ")])])]),_c('td',[_c('div',{staticClass:"vertical--align"},[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.saveEditData(props.item, 'tick')}}},[_vm._v("mdi-check")]),(!props.item.validate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.description))])]):_vm._e()],1)])])]}},{key:"footer.page-text",fn:function(){return [_c('v-btn',{staticClass:"mr-3",attrs:{"color":"danger","tile":"","elevation":"0"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"tile":"","elevation":"0","color":"primary","disabled":_vm.submitBtn},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")])]},proxy:true}],null,false,1606251062)})],1):_vm._e(),(_vm.openDialog)?_c('dialog-box',{attrs:{"open-dialog":_vm.openDialog,"table-length":_vm.tableLength},on:{"close-dialog":_vm.closeDialog,"save-data":_vm.saveData}}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }