import Vue from 'vue';
import axios from 'axios';
import Vuex from 'vuex';
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    queueType: "",
    settingType: "",
    photo: "",
    sessionTime: null,
    form_id: "",
    isFormDirty: false,
    submit:false,
    params: {},
    url: "",
  },
  getters: {
    getqueuetype(state) {
      return state.form_id;
    },
    getqueuetype(state) {
      return state.queueType;
    },
    getPhoto(state) {
      return state.photo;
    },
    getSessionTime(state) {
      return state.sessionTime;
    },
    getsettingtype(state) {
      return state.settingType;
    },
  },
  mutations: {
    async cancel() {
      // debugger
      this.loading = true;
      const params = store.state.params;
      let url = store.state.url;
      // params.id = store.state.id;
      // params.currently_assigned = store.state.currently_assigned;
      // params.user = "Not Assigned";
      let response = await axios.get(url, {
        params,
        headers: {
          accessToken: sessionStorage.accessToken,
        },
      });
      if (response) {
        this.loading = false;
        if (params.currently_assigned == "2") {
          store.getters.queueType = "Unassigned";
        } else if (params.currently_assigned == "0") {
          store.getters.queueType = "Review";
        }
        store.state.params = {};
        store.state.url = "";
      }
    },
  },
});
export default store;
